import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import {
  Ul, Question, FAQ, Div, Answer,
} from './styles';
import startupLife from '../../assets/vectors/startupLife.svg';

function StyledFAQ({ content }) {
  const [answer, setAnswer] = useState(0);
  return (
    <Div>
      <FAQ>
        <h3>{content.label}</h3>
        <Ul>
          {content.questions.map((question, index) => (
            <Question>
              <div>
                <h4>{question}</h4>
                <button
                  type="button"
                  onClick={() => (answer === index + 1 ? setAnswer(0) : setAnswer(index + 1))}
                >
                  <FaChevronDown className="question" size="1.2rem" />
                </button>
              </div>
              {answer === index + 1 && <Answer>{`lorem ips${index + 1}`}</Answer>}
            </Question>
          ))}
        </Ul>
      </FAQ>
      <img className="illustration" src={startupLife} alt={content.imgAlt} />
    </Div>

  );
}

export default StyledFAQ;
