import styled from 'styled-components';

export const Header = styled.div`

  width: 100%;
  height: 20.8rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 6.2rem 8.1rem 6.2rem 8.1rem;

  .logo {
    height: 8.7rem;
    margin-right: 8.82%;
  }

  .h5b {
    background: none;
    border: none;
    margin-left: 10.28%;
    min-width: fit-content;
  }

  .subButton {
    margin: auto 0 auto 2.7%;
  }

  h5 {
    font-size: ${({ theme }) => theme.fontsizes.heading5};
    text-transform: none;
    width: fit-content;
    max-height: 2.2rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xxlarge}){
    .h5b {
      margin-left: 5%;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}){
    * :not(img) {
      display: none;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}){
    button { 
      display: none;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}){
    .logo {
      height: 3.7rem;
      margin-right: 8.82%;
    }
    
    height: 10.3rem;
    padding: 3.4rem 3.7rem 3.4rem 3.7rem;

  }

`;
