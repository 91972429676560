import React from 'react';
import MercadoLivreLogo from '../../assets/vectors/mercadoLivre.svg';
import WoowopLogo from '../../assets/vectors/woowop.svg';
import TelefonicaLogo from '../../assets/vectors/telefonica.svg';
import RappiLogo from '../../assets/vectors/rappi.svg';
import { Ul, Div } from './styles';

function CompaniesLogos({ content, ...props }) {
  return (
    <Div {...props}>
      <h3>{content.companiesLabel}</h3>
      <Ul>
        <img className="companyLogo" src={MercadoLivreLogo} alt="Logo Mercado Livre" />
        <img className="companyLogo" src={WoowopLogo} alt="Logo Woowop" />
        <img className="companyLogo" src={TelefonicaLogo} alt="Logo Telefonica" />
        <img className="companyLogo" src={RappiLogo} alt="Logo Rappi" />
      </Ul>
    </Div>
  );
}

export default CompaniesLogos;
