import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CandidateContext from '../../context/candidatecontext';
import {
  DeviceContainer,
  LeftContainer,
  RightContainer,
  Select,
  Phases,
  TextInput,
  Radio,
  Button,
} from '../../components';
import api from '../../services';

const page = {
  title: 'Área de atuação',
  description: 'Queremos saber onde você atua ou quer atuar nos próximos anos.',
  inputs: {
    titleLabel: 'Qual é o seu título?',
    areaLabel: 'Qual é a sua área?',
    titlePlaceholder: 'Ex: Engenheira de Software',
    experienceLabel: 'Tempo de experiência',
    buttonLabel: 'PRÓXIMO',
  },
};
const formsWarnings = {
  title: {
    min: 'Must be at least 2 characters',
    max: 'Must be 36 characters or less',
    required: 'Required',
  },
  area: {
    required: 'Required',
  },
  experience: {
    required: 'Required',
  },
};
const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, formsWarnings.title.min)
    .max(36, formsWarnings.title.max)
    .required(formsWarnings.title.required),
  area: Yup.string()
    .required(formsWarnings.area.required),
  experience: Yup.string().required(formsWarnings.experience.required),
});

const initialValues = {
  title: '',
  area: '',
  experience: '',
};

export function Area() {
  const navigate = useNavigate();
  const { state, setState } = useContext(CandidateContext);
  const [selectOptions, setSelectOptions] = useState([]);
  const [experienceTime, setExperiencetime] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const select = await api.get('/expertises');
      const experience = await api.get('/experience-time');
      setSelectOptions(select.data);
      setExperiencetime(experience.data);
    };
    getData();
  }, []);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const {
      title, area, experience,
    } = values;
    api.post('/candidates/add_expertise', {
      id: state.candidate_id,
      expertise_id: area,
      experience_time_id: experience,
    }).then((res) => {
      console.log(res);
      resetForm();
      setSubmitting(false);
      navigate('/skills');
    }).catch((err) => console.log(err));
  };
  return (
    <DeviceContainer>
      <LeftContainer>
        <h1>
          {page.title}
        </h1>
        <h2>
          {page.description}
        </h2>
      </LeftContainer>
      <RightContainer>
        <Phases phasesN={6} phaseStatus={2} />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values, handleChange, handleBlur, handleSubmit, isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Select
                options={selectOptions}
                label={page.inputs.areaLabel}
                name="area"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.area}
              />
              <TextInput
                label={page.inputs.titleLabel}
                name="title"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                placeholder={page.inputs.titlePlaceholder}
              />
              <Radio
                options={experienceTime}
                label={page.inputs.experienceLabel}
                name="experience"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Button
                type="submit"
                label={isSubmitting ? 'Loading ...' : page.inputs.buttonLabel}
                disabled={isSubmitting}
                state={isSubmitting ? 'disabled' : 'active'}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </DeviceContainer>
  );
}
