import React from 'react';
import { useField } from 'formik';
import { Container } from './styles';

function StyledTextInput({ label, disabled, ...props }) {
  const [field, meta] = useField(props);

  return (
    <Container
      disabled={disabled}
      error={meta.error}
      touched={meta.touched}
    >
      <label htmlFor={props.name}>
        {label}
      </label>
      <input {...field} {...props} disabled={disabled} />
      {meta.touched && meta.error && !(props.className === 'dateInput') ? (<p className="error">{meta.error}</p>) : ''}
    </Container>
  );
}

export default StyledTextInput;
