import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  padding: 10px 14px 10px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;

    color: ${({
    theme, error, touched, disabled,
  }) => {
    if (error && touched) return theme.colors.accent2;
    if (disabled) return theme.colors.secondary2;

    return theme.colors.primary1;
  }};
  

  label{
    font-size: ${({ theme }) => theme.fontsizes.body1};
    color: ${({
    theme, error, touched, disabled,
  }) => {
    if (error && touched) return theme.colors.accent2;
    if (disabled) return theme.colors.grey300;

    return theme.colors.primary2;
  }};
  }

  p{
    color: ${({ theme }) => theme.colors.accent2};
    font-size: ${({ theme }) => theme.fontsizes.body2};
  }
`;
