import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { ButtonA } from './styles';

function StyledAddButton({ label, state }) {
  return (
    <ButtonA state={state}>
      {label}
      <FaPlus size={21.88} />
    </ButtonA>
  );
}

export default StyledAddButton;
