import React from 'react';
import { useField } from 'formik';
import { Container } from './styles';

function StyledSearchContainer({
  label, ...props
}) {
  const [meta] = useField(props);
  return (
    <Container error={meta.error} touched={meta.touched}>
      <label htmlFor={props.name}> {label}</label>
      {meta.touched && meta.error ? (<p>{meta.error}</p>) : ''}
    </Container>
  );
}

export default StyledSearchContainer;
