import styled from 'styled-components';

export const Footer = styled.div`

  width: 100%;
  height: 27rem;

  background: ${({ theme }) => theme.colors.secondary2};

  div{
    align-self: flex-start;
    height: 5rem;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 5.5rem 5.2rem 2rem 37.4rem;

  .bb, body, a {
    background: none;
    font-size: ${({ theme }) => theme.fontsizes.body1};
    min-width: fit-content;
    max-height: 2.2rem;
    color: ${({ theme }) => theme.colors.grey100};
  }

  .bb {
    background: none;
    border: none;
  }

  a {
    margin-top: auto;
    align-self: flex-end;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}){
    display: none;
  }

`;
