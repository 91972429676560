import styled from 'styled-components';

export const Div = styled.div`
  display: block;
  button {
    margin: auto;
  }
  margin-bottom: 6.4rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}){
    display: block;
    button {
      width: 100%;     
    }
    padding: 0 3.7rem;
    margin-top: 1rem;
    margin-bottom: 6rem;
  }
`;

export const Ul = styled.ul`
  width: 100%;
  height: flex;
  margin-bottom: 7.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 8.7rem;

  div {
    height: 18.5rem;
    width: 1px;
    background: ${({ theme }) => theme.colors.secondary2}; 
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}){
    display: block;
    margin-bottom: 0;
    div {
      display: none;
    }
    padding: 0;
  }

`;

export const Feature = styled.li`
  width: 34rem;
  height: 18.5rem;

  display: block;
  align-items: center;
  text-align: center;

  .icon {
    color: ${({ theme }) => theme.colors.accent2};
    margin-bottom: 2rem;
  }

  h3 {
    font-size: ${({ theme }) => theme.fontsizes.heading3};
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.accent2};
    text-align: left;
  }
  body {
    font-size: ${({ theme }) => theme.fontsizes.body1};
    text-align: left;
    background: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}){
    h3 {
      width: 100%;
      font-size: ${({ theme }) => theme.fontsizes.heading4};
    }
    width: 100%;
    margin: 0;
  }

`;
