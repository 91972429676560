import PhoneInput from 'react-phone-number-input';
import React from 'react';
import { useField } from 'formik';
import { Container } from './styles';
import 'react-phone-number-input/style.css';

function StyledPhoneInput({ label, disabled, ...props }) {
  const [field, meta] = useField(props);

  return (
    <Container disabled={disabled} error={meta.error} touched={meta.touched}>
      <label htmlFor={props.name}>
        {label}
      </label>
      <PhoneInput
        className="phoneNumInput"
        {...field}
        {...props}
        disabled={disabled}
      />
      {meta.touched && meta.error ? (<p>{meta.error}</p>) : ''}
    </Container>
  );
}

export default StyledPhoneInput;
