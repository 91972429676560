import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 3.6rem;

  label{
    font-size: ${({ theme }) => theme.fontsizes.body1};
    color: ${({
    theme, error, touched, disabled,
  }) => {
    if (error && touched) return theme.colors.accent2;
    if (disabled) return theme.colors.grey300;

    return theme.colors.primary2;
  }};
  }

  --PhoneInputCountryFlag-height: 2.5rem;

  .PhoneInputCountrySelectArrow {
    width: 0.7rem;
    height: 0.7rem;
    margin: 0 0 0 1rem;

  }

  .PhoneInputCountrySelect{
    width: 8rem;

  }

  .PhoneInput{
    height:flex;
    display: flex;
    align-items: start;
  }

  .PhoneInputCountry{
    margin: 0 1rem 0 0;
    width: 8rem;
    border-style: solid;
    border-radius: 4px;
    border-width: 2px;

    padding-left: 1rem;

    cursor: text;

    background-color: transparent;
    border-color: ${({
    theme, error, touched, disabled,
  }) => {
    if (error && touched) return theme.colors.accent2;
    if (disabled) return theme.colors.grey300;

    return theme.colors.grey700;
  }};
  }

  .PhoneInputCountryIcon{
    margin: 0;
    background: none;
    border-width: 0;
  }

  .PhoneInputInput{
    width: 100%;
    height: 5rem;
    line-height: 5rem;
    border-style: solid;
    border-radius: 4px;
    border-width: 2px;

    padding: 0 0 0 2.2rem;

    cursor: text;

    background-color: transparent;
    border-color: ${({
    theme, error, touched, disabled,
  }) => {
    if (error && touched) return theme.colors.accent2;
    if (disabled) return theme.colors.grey300;

    return theme.colors.grey700;
  }};

    color: ${({
    theme, error, touched, disabled,
  }) => {
    if (error && touched) return theme.colors.accent2;
    if (disabled) return theme.colors.grey300;

    return theme.colors.primary1;
  }};
      font-size: ${({ theme }) => theme.fontsizes.body1};
  }

  p{
    color: ${({ theme }) => theme.colors.accent2};
    font-size: ${({ theme }) => theme.fontsizes.body2};
  }
  // Esse seletor vai funcionar quando qualquer elemento da div tiver focado
  
  &:focus-within{
    label{
      color: ${({ theme, disabled }) => !disabled && theme.colors.secondary2};
    }
    input{
      border-color: ${({ theme, disabled }) => !disabled && theme.colors.secondary2};
    }
  }
`;
