import styled from 'styled-components';

export const Div = styled.div`
  height: flex;

  display: flex;
  justify-content: space-between;

  padding: 1.7rem 12.5rem 7rem 8.2rem;

  img {
    height: 54rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}){
    padding: 0 3.7rem;
    img{
      display: none;
    }
  }
`;

export const FAQ = styled.div`
  display: block;
  text-align: left;
  margin-top: 4.3rem;

  h3 {
    font-size: ${({ theme }) => theme.fontsizes.heading3};
    color: ${({ theme }) => theme.colors.accent2};
    text-align: left;
    margin-bottom: 4rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}){
    margin-top: 1rem;
    width: 100%;
    margin-bottom: 10rem;
    h3{
      font-size: ${({ theme }) => theme.fontsizes.heading4};
      margin-bottom: 1.6rem;
    }
  }
`;

export const Ul = styled.ul`
  display: block;
  align-items: center;
  justify-content: space-between;

`;

export const Question = styled.div`
  width: 60.4rem;
  height: fit-content;

  padding: 0 1rem 0 0.5rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.accent1};

  display: block;
  
  div {
    height: 7.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background: none;
      border: none;
    }
  }

  svg {
    color: ${({ theme }) => theme.colors.accent2};
  }

  h4 {
    font-size: ${({ theme }) => theme.fontsizes.heading4};
    width: 34rem;
    text-align: left;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}){
    width: 100%;
    div{ 
      height: 5.2rem;
    }
    h4{
      font-size: ${({ theme }) => theme.fontsizes.body1};
      width: 100%;
    }
  }

`;

export const Answer = styled.li`
  width: 100%;
  height: 15rem;
  padding: 1.5rem; 
  box-shadow: inset 0 0 0.3rem ${({ theme }) => theme.colors.accent2}, 0 0 0.1rem ${({ theme }) => theme.colors.accent2};
  margin-bottom: 3rem;
  font-size: ${({ theme }) => theme.fontsizes.heading5};
`;
