import React from 'react';
import { FaGift, FaGlobe, FaComments } from 'react-icons/fa';
import { Ul, Feature, Div } from './styles';
import Button from '../Button';

function FeatureIcons({ content, ...props }) {
  return (
    <Div>
      <Ul>
        <Feature>
          <FaGift className="icon" size="5.7rem" />
          <h3>{content.free.label}</h3>
          <body>{content.free.description}</body>
        </Feature>
        <div />
        <Feature>
          <FaGlobe className="icon" size="6rem" />
          <h3>{content.global.label}</h3>
          <body>{content.global.description}</body>
        </Feature>
        <div />
        <Feature>
          <FaComments className="icon" size="5rem" />
          <h3>{content.support.label}</h3>
          <body>{content.support.description}</body>
        </Feature>
      </Ul>
      <a href={props.hrefButton}>
        <Button onClick={props.onClickButton} state="active" size="small" label={content.buttonLabel} />
      </a>

    </Div>
  );
}

export default FeatureIcons;
