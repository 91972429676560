import React from 'react';
import { ButtonN } from './styles';

function StyledButton({ label, state, ...props }) {
  return (
    <ButtonN state={state} {...props}>{label}</ButtonN>
  );
}

export default StyledButton;
