import React from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import {
  DeviceContainer,
  LeftContainer,
  RightContainer,
  Select,
  Phases,
  TextInput,
  Button,
} from '../../components';

const page = {
  title: 'Perfil',
  description: 'Complete seu perfil para ter mais visualizações',
  inputs: {
    nameLabel: 'Nome',
    emailLabel: 'E-mail',
    phoneLabel: 'Telefone',
    genderLabel: 'Gênero',
    diversityLabel: 'Diversidade',
    diversityDescription: 'Essas perguntas são opcionais;',
    disabilityLabel: 'Você é uma pessoa com deficiência?',
    raceLabel: 'Raça',
    buttonLabel: 'FINALIZAR',
  },
};

const formsWarnings = {
  gender: {
    required: 'Required',
  },
  disability: {
    required: 'Required',
  },
  race: {
    required: 'Required',
  },
};
const validationSchema = object({
  gender: string()
    .required(formsWarnings.gender.required),
  disability: string()
    .required(formsWarnings.disability.required),
  race: string()
    .required(formsWarnings.race.required),
});

const onSubmit = (values, { setSubmitting, resetForm }) => {
  setTimeout(() => {
    alert(JSON.stringify(values, null, 2));
    resetForm();
    setSubmitting(false);
  }, 1500);
};
const initialValues = {
  name: 'fill with previous form',
  email: 'fill with previous form',
  phone: 'fill with previous form',
  gender: '',
  disability: '',
  race: '',
};

const selectGender = [
  'Male',
  'Female',
  'Non-Binary',
];
const selectDeficiency = [
  'Sim',
  'Não',
];
const selectRace = [
  'Preto',
  'Pardo',
  'Branco',
];

export function Profile() {
  // const navigate = useNavigate();
  return (
    <DeviceContainer>
      <LeftContainer>
        <h1>
          {page.title}
        </h1>
        <h2>
          {page.description}
        </h2>
      </LeftContainer>
      <RightContainer>
        <Phases phasesN={6} phaseStatus={6} />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values, handleChange, handleBlur, handleSubmit, isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <TextInput
                label={page.inputs.nameLabel}
                name="name"
                type="text"
                disabled
              />
              <TextInput
                label={page.inputs.emailLabel}
                name="email"
                type="text"
                disabled
              />
              <TextInput
                label={page.inputs.phoneLabel}
                name="phone"
                type="phone"
                disabled
              />
              <h1>{page.inputs.diversityLabel}</h1>
              <h2>{page.inputs.diversityDescription}</h2>
              <Select
                options={selectGender}
                label={page.inputs.genderLabel}
                name="gender"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.gender}
              />
              <Select
                options={selectDeficiency}
                label={page.inputs.disabilityLabel}
                name="disability"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.disability}
              />
              <Select
                options={selectRace}
                label={page.inputs.raceLabel}
                name="race"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.race}
              />
              <Button
                type="submit"
                label={isSubmitting ? 'Loading ...' : page.inputs.buttonLabel}
                disabled={isSubmitting}
                state={isSubmitting ? 'disabled' : 'active'}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </DeviceContainer>
  );
}
