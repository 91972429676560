import styled from 'styled-components';

export const DateContainer = styled.div`
 
  width: 45rem;

  .dateInput {
    display: block;
    input{
      min-width: 8.4rem;
      margin-right: 2rem
    }
  }

  display: flex;
  align-items: end;
  
  .noBar {
    align-self: center;
    min-width: 0.2rem;
    min-height: 2.5rem;
    margin: 0 1rem 1.5rem 1rem;
    transform: rotate(10deg);
    background: transparent;
  }
  .bar {
    align-self: center;
    min-width: 0.2rem;
    min-height: 2.5rem;
    margin: 0 0.8rem 1.5rem 0.8rem;
    transform: rotate(10deg);
    background-color: ${({ theme }) => theme.colors.grey700};
  }
`;

export const DeviceContainer = styled.div`

  min-height: 100%;
  display: flex;
  height: flex;
  
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}){
    //width: 30.1rem;
    display: block;
  }
`;

export const LeftContainer = styled.div`

  height: flex;
  padding: 10.7rem 12.1rem 0 8rem;
  width: 50%;

  background: ${({ theme, type }) => {
    if (type === 'pink') return theme.colors.vtex2;

    return theme.colors.secondary2;
  }};
  color: ${({ theme }) => theme.colors.grey100};


  h1 {
    font-size: ${({ theme }) => theme.fontsizes.heading2};
    text-transform: capitalize;
    height: flex;
    margin-bottom: .4rem;
  }
  h2 {
    font-size: ${({ theme }) => theme.fontsizes.heading4};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}){
    height: 11.7rem;
    width: 100%;
    padding: 3.7rem 8.9rem 0 2.7rem;

    h1 {
      font-size: ${({ theme }) => theme.fontsizes.heading4};
      height: 3rem;
      margin-bottom: .4rem;  
    }
    h2 {
      font-size: ${({ theme }) => theme.fontsizes.body1};
    }
  }
`;

export const RightContainer = styled.div`
  width: 50%;

  display: block;
  text-align: center;
  padding: 10.7rem 8rem 8rem 12.1rem;

  .OfficeButtons {
    *{
      width: 47.5%;
      height: 5rem;
    }
    justify-content: space-between;
    display: flex;
  }
  // margin bottom on phases seria melhor para quando remove-lo, rever


  Form {
    *:not(button) {
      text-align: left;
    }
    input {
      text-align: left;
    }

    >h1 {
      font-size: ${({ theme }) => theme.fontsizes.heading5};
      text-transform: capitalize;
      color: ${({ theme }) => theme.colors.primary2};
      margin-bottom: 0.4rem;
      margin-top: 3.2rem;
    }
    >h2 {
      font-size: ${({ theme }) => theme.fontsizes.body1};
      color: ${({ theme }) => theme.colors.grey700};
      margin-bottom: 1.6rem;
    }
    
    .radio {
      display: block;
      align-items: left;
    }
    div{
      margin-bottom: 3.6rem
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}){
      div{
        margin-bottom: 1.6rem;
      }
    }
    
  }


  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}){
    padding: 1.6rem 3.5rem 1rem 3.4rem;
    width: 100%;
  }
`;
