import styled from 'styled-components';

export const ButtonN = styled.button`

  height: 6.8rem;
  line-height: 6.8rem;
  margin-top: 3.2rem;

  border-radius: 4px;
  border: none;
  
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  
  font-size: ${({ theme }) => theme.fontsizes.heading4};
  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.grey100};
    
  background: ${({ theme, state }) => {
    if (state === 'active') return theme.colors.secondary2;
    if (state === 'error') return theme.colors.accent2;
    if (state === 'default') return theme.colors.grey700;

    return theme.grey300;
  }};

  //use another props to set button size?
  min-width: ${({ size }) => {
    if (size === 'small') return '25rem';
    if (size === 'smaller') return '22.7rem';

    return '100%';
  }};

  /* @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}){
    width: 25rem
  } */

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}){
    height: 5rem;
    line-height: 5rem;
    font-size: ${({ theme }) => theme.fontsizes.body1};
  }

  transition: 0.2s;

  &:hover {
    box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.1);
  };


`;
