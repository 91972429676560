import React, { createContext, useState } from 'react';
// ⦁ DEFAULT_VALUE: O nosso valor default que passaremos para nosso context como
// parâmetro do hook createContext. Passamos o state com os dados zerados e uma
// função de inicialização.
const DEFAULT_VALUE = {
  state: {
    candidate_id: 'e6e0c787-d427-4d2e-89e7-4ee9e6585174',
  },
  setState: () => {},
};

const CandidateContext = createContext(DEFAULT_VALUE);

// Provider: Ele proverá o context aos componentes filhos da árvore.
// Esse componente receberá a propriedade value, o estado Global e a
// nossa função que modifica o estado.
function CandidateContextProvider({ children }) {
  const [state, setState] = useState(DEFAULT_VALUE.state);
  return (
    <CandidateContext.Provider value={{ state, setState }}>
      {children}
    </CandidateContext.Provider>
  );
}

export { CandidateContextProvider };
export default CandidateContext;
