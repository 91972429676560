import styled from 'styled-components';

export const Div = styled.div`
  height: fit-content;

  display: flex;
  justify-content: space-between;

  padding: 1.7rem 12.5rem 7rem 8.2rem;

  img {
    height: 54rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}){
  padding: 0 3.7rem;
  margin-bottom: 4rem;
    img{
      display: none;
    }
  }

`;

export const Ul = styled.ul`
  width: 54rem;
  display: block;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.3rem;

  h3 {
    font-size: ${({ theme }) => theme.fontsizes.heading3};
    color: ${({ theme }) => theme.colors.accent2};
    text-align: left;
    margin-bottom: 4rem;
  }
  h4 {
    font-size: ${({ theme }) => theme.fontsizes.heading4};
    text-align: left;
    margin-bottom: 4rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}){
    h3 {
      font-size: ${({ theme }) => theme.fontsizes.heading4};
      margin-bottom: 2rem;
    }
    h4 {
      font-size: ${({ theme }) => theme.fontsizes.body1};
      margin-bottom: 4rem;
    }
    button{
      width: 100%;
    }
  }
`;
