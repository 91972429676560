import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer } from './styles';

function StyledFooter({ content }) {
  const navigate = useNavigate();

  return (
    <Footer>
      <div>
        <button className="bb" onClick={() => navigate('/')} type="button">
          <body>{content.candidateLabel}</body>
        </button>
        <button className="bb" onClick={() => navigate('/enterprise')} type="button">
          <body>{content.companyLabel}</body>
        </button>
      </div>
      <a href="https://storyset.com/">Business illustrations by Storyset</a>
    </Footer>
  );
}

export default StyledFooter;
