import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 3.6rem;

  label{
    font-size: ${({ theme }) => theme.fontsizes.body1};
    color: ${({
    theme, error, touched, disabled,
  }) => {
    if (error && touched) return theme.colors.accent2;
    if (disabled) return theme.colors.grey300;

    return theme.colors.primary2;
  }};
  }



  input{
    width: 100%;
    height: 5rem;
    line-height: 5rem;
    border-style: solid;
    border-radius: 4px;
    border-width: 2px;

    padding: 0 0 0 2.2rem;

    cursor: text;

    background-color: transparent;
    border-color: ${({
    theme, error, touched, disabled,
  }) => {
    if (error && touched) return theme.colors.accent2;
    if (disabled) return theme.colors.grey300;

    return theme.colors.grey700;
  }};

    color: ${({
    theme, error, touched, disabled,
  }) => {
    if (error && touched) return theme.colors.accent2;
    if (disabled) return theme.colors.grey300;

    return theme.colors.primary1;
  }};
    font-size: ${({ theme }) => theme.fontsizes.body1};
  }

  p{
    color: ${({ theme }) => theme.colors.accent2};
    font-size: ${({ theme }) => theme.fontsizes.body2};
  }
  // Esse seletor vai funcionar quando qualquer elemento da div tiver focado
  
  &:focus-within{
    label{
      color: ${({ theme, disabled }) => !disabled && theme.colors.secondary2};
    }
    input{
      border-color: ${({ theme, disabled }) => !disabled && theme.colors.secondary2};
    }
  }
`;
