import React from 'react';
import { Ul, Div } from './styles';
import Creativity from '../../assets/vectors/creativity.svg';
import Button from '../Button';

function SpecialistsDescription({ content, ...props }) {
  return (
    <Div>
      <img className="illustration" src={Creativity} alt={content.imgAlt} />
      <Ul>
        <h3>{content.title}</h3>
        <h4>{content.description}</h4>
        <a href={props.hrefButton}>
          <Button onClick={props.onClickButton} state="error" size="small" label={content.buttonLabel} />
        </a>
      </Ul>
    </Div>

  );
}

export default SpecialistsDescription;
