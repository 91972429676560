import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import {
  DeviceContainer,
  LeftContainer,
  RightContainer,
  TextInput,
  PhoneInput,
  Button,
} from '../../components';
import api from '../../services';

const page = {
  title: 'Conta',
  description: 'Aqui você cria o perfil dentro da nossa plataforma',
  inputs: {
    nameLabel: 'Nome',
    namePlaceholder: 'Ex: Gabriela',
    last_nameLabel: 'Sobrenome',
    last_namePlaceholder: 'Ex: Dias de Souza',
    companyNameLabel: 'Nome da Empresa',
    companyNamePlaceholder: 'Ex: VTEX',
    passwordLabel: 'Senha',
    phoneLabel: 'Telefone',
    passwordPlaceholder: '****',
    buttonLabel: 'PRÓXIMO',
  },
};
const formsWarnings = {
  name: {
    min: 'Must be at least 2 characters',
    max: 'Must be 15 characters or less',
    required: 'Required',
  },
  last_name: {
    min: 'Must be at least 2 characters',
    max: 'Must be 15 characters or less',
    required: 'Required',
  },
  companyName: {
    min: 'Must be at least 3 characters',
    max: 'Must be 20 characters or less',
    required: 'Required',
  },
  password: {
    min: 'Must be at least 8 characters',
    max: 'Must be 20 characters or less',
    required: 'Required',
    match: 'Must Contain 8 Characters, one Uppercase, one Lowercase',
  },
};

const validationSchema = object({
  name: string()
    .min(2, formsWarnings.name.min)
    .max(15, formsWarnings.name.max)
    .required(formsWarnings.name.required),
  last_name: string()
    .min(2, formsWarnings.last_name.min)
    .max(15, formsWarnings.last_name.max)
    .required(formsWarnings.last_name.required),
  enterprise_name: string()
    .min(3, formsWarnings.last_name.min)
    .max(20, formsWarnings.last_name.max)
    .required(formsWarnings.last_name.required),
  password: string()
    .required(formsWarnings.password.required)
    .min(8, formsWarnings.password.min)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/,
      formsWarnings.password.match,
    ),
});

export function RegistrationEnterprise() {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const {
      name, last_name, password, enterprise_name,
    } = values;
    api.post('/enterprise-login', {
      // id: state.enterprise_id,
      // email: state.email,
      name,
      last_name,
      enterprise_name,
      phone,
      password,
    }).then(() => {
      resetForm();
      setSubmitting(false);
      // navigate('/?');
    }).catch((err) => console.log(err));
  };

  const initialValues = {
    name: '',
    last_name: '',
    companyName: '',
    password: '',
    phone,
  };

  return (
    <DeviceContainer>
      <LeftContainer type="pink">
        <h1>
          {page.title}
        </h1>
        <h2>
          {page.description}
        </h2>
      </LeftContainer>
      <RightContainer>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values, handleChange, handleBlur, handleSubmit, isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <TextInput
                label={page.inputs.nameLabel}
                name="name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                placeholder={page.inputs.namePlaceholder}
              />
              <TextInput
                label={page.inputs.last_nameLabel}
                name="last_name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.last_name}
                placeholder={page.inputs.last_namePlaceholder}
              />
              <PhoneInput
                label={page.inputs.phoneLabel}
                name="phone"
                type="text"
                onChange={setPhone}
                onBlur={handleBlur}
                international="false"
              />
              <TextInput
                label={page.inputs.passwordLabel}
                name="password"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                placeholder={page.inputs.passwordPlaceholder}
              />
              <Button
                type="submit"
                label={isSubmitting ? 'Loading ...' : page.inputs.buttonLabel}
                disabled={isSubmitting}
                state={isSubmitting ? 'disabled' : 'active'}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </DeviceContainer>
  );
}
