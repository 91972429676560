import styled from 'styled-components';

export const Container = styled.div`
  select{
    width: 100%;
    height: 5rem;
    font-size: ${({ theme }) => theme.fontsizes.body1};
    line-height: 5rem;

    padding: 0 0 0 2.2rem;
    
    background-color:transparent;
    border-style: solid;
    border-radius: 4px;
    border-width: 2px;

    cursor: pointer;

    border-color: ${({
    theme, error, touched, disabled,
  }) => {
    if (error && touched) return theme.colors.accent2;
    if (disabled) return theme.colors.grey300;

    return theme.colors.grey700;
  }};

    color: ${({
    theme, error, touched, disabled,
  }) => {
    if (error && touched) return theme.colors.accent2;
    if (disabled) return theme.colors.secondary2;

    return theme.colors.primary1;
  }};
  }

  label{
    font-size: ${({ theme }) => theme.fontsizes.body1};
    color: ${({
    theme, error, touched, disabled,
  }) => {
    if (error && touched) return theme.colors.accent2;
    if (disabled) return theme.colors.grey300;

    return theme.colors.primary2;
  }};
  }

  p{
    color: ${({ theme }) => theme.colors.accent2};
    font-size: ${({ theme }) => theme.fontsizes.body2};
  }
  &:focus-within{
    label{
      color: ${({ theme }) => theme.colors.secondary2};
    }
    select{
      border-color: ${({ theme }) => theme.colors.secondary2};
    }
  }
`;
