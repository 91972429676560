import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CandidateContext from '../../context/candidatecontext';
import {
  CompaniesLogos, FAQ, FeatureIcons, Footer, Header, HeadlineInput,
} from '../../components';

import api from '../../services';

const formsWarnings = {
  email: {
    required: 'Required',
  },
};

const headerContent = {
  candidateLabel: 'para você',
  companyLabel: 'para empresas',
  buttonLabel: 'CADASTRE-SE',
  logoAlt: 'Logo do Commerce talents',
};

const headlineContent = {
  headline1: 'As melhores ',
  highlighted: 'vagas ',
  headline2: 'do mercado digital',
  subheadline: 'Cadastre-se e seja visto por centenas de recrutadores mundialmente',
  emailPlaceholder: 'Seu melhor e-mail',
  buttonLabel: 'CADASTRE-SE',
};

const featureIconContent = {
  free: {
    label: 'Totalmente gratuito',
    description: 'Sem qualquer custo para você. Desde a vaga até a contratação',
  },
  global: {
    label: 'Vagas globais',
    description: 'Trabalhe em qualquer lugar do mundo',
  },
  support: {
    label: 'Suporte total',
    description: 'Receba dicas de entrevista e consiga seu emprego mais rapidamente',
  },
  buttonLabel: 'CADASTRE-SE',
};

const companiesContent = {
  companiesLabel: 'Empresas que contratam',
};

const faqContent = {
  label: 'Ainda tem dúvidas?',
  questions: ['1. Eu tenho que pagar algo?',
    '2. Dúvida 2',
    '3. Dúvida 3',
    '4. Dúvida 4'],
  imgAlt: 'Start Up life illustration by Storyset',
};

const footerContent = {
  candidateLabel: 'para você',
  companyLabel: 'para empresas',
  copyright: 'Business illustrations by Storyset',
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(formsWarnings.email.required),
});

const initialValues = {
  email: '',
};

export function Candidate() {
  const navigate = useNavigate();

  const { state, setState } = useContext(CandidateContext);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const {
      email,
    } = values;
    console.log(state);
    api.post('/leads', {
      // id: state.candidate_id,
      id: 'e6e0c787-d427-4d2e-89e7-4ee9e6585174',
      email,
    }).then((res) => {
      setState({
        candidate_id: res.data.id,
        email: res.data.email,
      });
      console.log(state);
      resetForm();
      setSubmitting(false);
      navigate('/login-creation');
    }).catch((err) => console.log(err));
  };

  function addGlow() {
    const buttonInput = document.getElementById('buttonInput');
    buttonInput.classList.add('glow');
    setTimeout(() => {
      buttonInput.classList.remove('glow');
    }, 1000);
  }

  return (
    <>
      <Header onClickButton={addGlow} content={headerContent} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values, handleChange, handleBlur, handleSubmit, isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <HeadlineInput
              name="email"
              page="candidate"
              content={headlineContent}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              disabled={isSubmitting}
              state={isSubmitting ? 'disabled' : 'active'}
            />
          </Form>
        )}
      </Formik>
      <FeatureIcons onClickButton={addGlow} hrefButton="#join" content={featureIconContent} />
      <CompaniesLogos page="candidate" content={companiesContent} />
      <FAQ content={faqContent} />
      <Footer content={footerContent} />
    </>
  );
}
