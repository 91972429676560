import styled from 'styled-components';

export const Container = styled.div`
  input{
    background-color: ${({ theme }) => theme.colors.grey100};
    border-radius: 4px;
    width: 100%;
    height: 5rem;
    line-height: 5rem;
    border-radius: 4px;
    padding: 0 0 0 2.2rem;
    border: none;

    font-size: ${({ theme }) => theme.fontsizes.body1};

    color: ${({ theme }) => theme.colors.primary1};
    ::placeholder{
      color:${({ theme }) => theme.colors.grey700};
    }
  }

`;

export const SkillsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.vtex1};
  border-radius: 4px;
  padding: 13px 22px;
  display: flex;
  width:100%;
  max-height: 172px;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: auto;
  justify-content: space-between;

  ::-webkit-scrollbar{
    display: none;
  }
`;

export const Skill = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: 4px;
  padding: 5px 16px;
  margin: 0 10px 0 10px;
  font-size: ${({ theme }) => theme.fontsizes.body1};
  flex-shrink: none;

  &:last-child{
    margin-right: auto;
  }
`;
