import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Area } from '../features/RegisterPF/area';
import { LoginCreation } from '../features/RegisterPF/login-creation';
import { ProfessionalExperience } from '../features/RegisterPF/professional-experience';
import { Skills } from '../features/RegisterPF/skills';
import { Profile } from '../features/RegisterPF/profile';
import { Experience } from '../features/RegisterPF/experience';
import { Career } from '../features/RegisterPF/career';
import { EducationalExperience } from '../features/RegisterPF/educational-experience';
import { Candidate } from '../features/LP/candidate';
import { Enterprise } from '../features/LP/enterprise';
import { RegistrationEnterprise } from '../features/RegisterPJ/registration-enterprise';

function Routing() {
  return (
    <Routes>
      <Route path="/login-creation" element={<LoginCreation />} />
      <Route path="/registration/enterprise" element={<RegistrationEnterprise />} />
      <Route path="/area" element={<Area />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/professional-experience" element={<ProfessionalExperience />} />
      <Route path="/educational-experience" element={<EducationalExperience />} />
      <Route path="/skills" element={<Skills />} />
      <Route path="/experience" element={<Experience />} />
      <Route path="/career" element={<Career />} />
      <Route path="/" element={<Candidate />} />
      <Route path="/enterprise" element={<Enterprise />} />

    </Routes>
  );
}

export default Routing;
