import styled from 'styled-components';

export const Container = styled.div`
  label{
    font-size: ${({ theme }) => theme.fontsizes.body1};
    color: ${({
    theme, error, touched, disabled,
  }) => {
    if (error && touched) return theme.colors.accent2;
    if (disabled) return theme.colors.secondary2;

    return theme.colors.grey700;
  }};
  }
  input {
      cursor: pointer;
  }

  h3{
    font-size: ${({ theme }) => theme.fontsizes.heading4};
    padding-bottom: 1rem;
  }
`;

export const ItemDiv = styled.div`
  display: flex;
  padding-top: 0.2rem;
  align-items: center;

  font-size: ${({ theme }) => theme.fontsizes.body1};

  label{
    margin-left: 8px;
  }
`;
