import React from 'react';
import { useField } from 'formik';
import { string } from 'yup';
import { Container } from './styles';

function StyledSelectExperience({
  label, options, ...props
}) {
  const [field, meta] = useField(props);
  return (
    <Container error={meta.error} touched={meta.touched}>
      <label htmlFor={props.name}> {label}</label>
      <select {...field} {...props}>
        <option disabled value=""> --Selecione uma opção-- </option>
        {options.map((option) => (
          <option value={option.id} key={option.id}>{option}</option>
        ))}
      </select>
      {meta.touched && meta.error ? (<p>{meta.error}</p>) : ''}
    </Container>
  );
}

export default StyledSelectExperience;
