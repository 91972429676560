import React, { useState, useEffect } from 'react';
import { Container, SkillsContainer, Skill } from './styles';

function Search({
  data, setData, label, ...props
}) {
  const [filteredData, setFilteredData] = useState(data);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const newFilter = data.filter((value) => (value.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ? value : null));
      console.log(newFilter);

      setFilteredData(newFilter);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const addData = (newData) => {
    setData((oldData) => {
      const filteredState = oldData.filter((state) => state !== newData);
      return [...filteredState, newData];
    });
  };

  return (
    <Container>
      <div>
        <label htmlFor={props.name}>
          {label}
        </label>
        <input type="text" placeholder={props.placeholder} onChange={(e) => setSearchTerm(e.target.value)} />

      </div>
      {
        filteredData.length !== 0
          && (
          <SkillsContainer>
            {filteredData.slice(0, 15).map((skill) => (
              <Skill onClick={() => addData(skill)}>
                <p>{skill}</p>
              </Skill>
            ))}
          </SkillsContainer>
          )
      }

    </Container>
  );
}
export default Search;
