import React from 'react';
import { useField } from 'formik';
import {
  Container, ItemDiv,
} from './styles';

function StyledRadio({
  label, options, ...props
}) {
  const [field, meta] = useField(props);
  return (
    <Container error={meta.error} touched={meta.touched}>
      <h3>{label}</h3>
      {options.map((option) => (
        <ItemDiv className="option">
          <input type="radio" {...field} {...props} value={option.id} />
          <label htmlFor={props.name}>{option.name}</label>
        </ItemDiv>
      ))}

    </Container>
  );
}

export default StyledRadio;
