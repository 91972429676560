import styled from 'styled-components';

export const Div = styled.div`
  display: block;

  h3 {
    font-size: ${({ theme }) => theme.fontsizes.heading3};
    color: ${({ theme, page }) => {
    if (page === 'candidate') return theme.colors.secondary2;
    if (page === 'enterprise') return theme.colors.accent2;

    return theme.grey700;
  }};
    text-align: left;
    margin-left: 8.1rem;
    margin-bottom: 1.6rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}){
    h3 {
      font-size: ${({ theme }) => theme.fontsizes.heading4};
      margin: 0 0 0 0;
    } 
    padding: 0 3.7rem;
  }
`;

export const Ul = styled.ul`
  width: 100%;
  height: 18.3rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${({ theme }) => theme.colors.grey100};

  padding: 0 8.7rem 0 8.7rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}){
    width: 100%;
    height: fit-content;

    overflow: auto;
    background: none;
    padding: 0 0;
    img {
      margin-right: 2.5rem;
    }
  }

`;
