import React from 'react';
import { HeadlineInput } from './styles';
import Button from '../Button';
import TextInput from '../TextInput';

function StyledheadlineInput({ content, ...props }) {
  return (
    <HeadlineInput id="join" {...props}>
      <h1>
        <p>
          {content.headline1}
          <span className="highlighted">{content.highlighted}</span>
          {content.headline2}
        </p>
      </h1>
      <h5>{content.subheadline}</h5>
      <div id="buttonInput" className="input">
        <TextInput
          name={props.name}
          type="email"
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          placeholder={content.emailPlaceholder}
        />
        <Button
          type="submit"
          size="smaller"
          label={props.isSubmitting ? 'Loading ...' : content.buttonLabel}
          disabled={props.disabled}
          state={props.state}
        />
      </div>

    </HeadlineInput>
  );
}

export default StyledheadlineInput;
