import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Form, Formik, FieldArray, Field,
} from 'formik';
import * as Yup from 'yup';
import CandidateContext from '../../context/candidatecontext';
import api from '../../services';
import {
  DeviceContainer,
  LeftContainer,
  RightContainer,
  Search,
  Phases,
  SelectExperience,
  Button,
  StyledCheckbox,
  StyledAddButton,
} from '../../components';

const page = {
  title: 'Experiência Profissional e Acadêmica',
  description: 'Como foi a sua história até aqui?',
  inputs: {
    searchPlaceholder: 'Pesquise uma lingua',
    lenguagesLabel: 'Quais linguas você fala?',
    professionalLabel: 'Experiências Profissionais',
    professionalCheck: 'Não tenho experiência profissional',
    professionalButton: 'Adicionar experiência profissional',
    academicLabel: 'Esperiências Acadêmicas',
    academicCheck: 'Não tenho experiência acadêmica',
    academicButton: 'Adicionar experiência acadêmica',
    buttonLabel: 'Próximo',
  },
};

const options = [
  'Básico',
  'Intermediario',
  'Avançado',
  'Fluente',
];

const formsWarnings = {
  lenguages: {
    required: 'Required',
  },
};

const validationSchema = Yup.object().shape({
  lenguages: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      experience: Yup.string().required(),
    }),
  ),
});

const lenguages = [
  'Inglês',
  'Portugues',
  'Espanhol',
  'Francês',
  'Alemão',
  'Mandarim',
  'Italiano',
  'Japonês',
  'Inglês',
  'Portugues',
  'Espanhol',
  'Francês',
  'Alemão',
  'Mandarim',
  'Italiano',
  'Japonês',
];

const initialValues = {
  professionalExperience: [],
  professionalCheck: [],
  academicExperience: [],
  academicCheck: [],

  lenguagesExperience: [],
};

export function Experience() {
  const { state, setState } = useContext(CandidateContext);
  const [lenguagesExperience, setlenguagesExperience] = useState([]);

  const navigate = useNavigate();
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const {
      title, area, experience,
    } = values;
    console.log(values);
    api.post('/candidates/add_expertise', {
      candidate_id: state.candidate_id,

    }).then((res) => {
      console.log(res);
      resetForm();
      setSubmitting(false);
      navigate('/skills');
    }).catch((err) => console.log(err));
  };
  return (
    <DeviceContainer>
      <LeftContainer>
        <h1>
          {page.title}
        </h1>
        <h2>
          {page.description}
        </h2>
      </LeftContainer>
      <RightContainer>
        <Phases phasesN={6} phaseStatus={4} />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values, handleChange, handleBlur, handleSubmit, isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <StyledCheckbox
                label={page.inputs.professionalLabel}
                text={page.inputs.professionalCheck}
                name="professionalCheck"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <StyledAddButton
                label={page.inputs.professionalButton}
                state="active"
              />
              <StyledCheckbox
                label={page.inputs.academicLabel}
                text={page.inputs.academicCheck}
                name="academicCheck"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <StyledAddButton
                label={page.inputs.academicButton}
                state="active"
              />
              <h1>
                {page.inputs.lenguagesLabel}
              </h1>
              <Search
                data={lenguages}
                placeholder={page.inputs.searchPlaceholder}
                setData={setlenguagesExperience}
              />
              <FieldArray name="lenguagesExperience">
                <>
                  {lenguagesExperience.map((lenguage, i) => (
                    <SelectExperience
                      label={lenguage}
                      options={options}
                      name={`lenguagesExperience.${i}.${lenguage}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  ))}
                </>
              </FieldArray>

              <Button
                type="submit"
                label={isSubmitting ? 'Loading ...' : page.inputs.buttonLabel}
                disabled={isSubmitting}
                state={isSubmitting ? 'disabled' : 'active'}
              />

            </Form>
          )}
        </Formik>
      </RightContainer>
    </DeviceContainer>
  );
}
