export const theme = {
  colors: {
    primary1: '#151512',
    primary2: '#000000',
    secondary1: '#6BBEA1',
    secondary2: '#068460',
    secondary3: '#056B4E',
    accent1: '#FF7129',
    accent2: '#EA5B13',
    accent3: '#D15111',
    vtex1: '#FFBCCF',
    vtex2: '#F3A7BD',
    vtex3: '#D995A9',
    grey100: '#F4F4F4',
    grey300: '#C2C8C6',
    grey500: '#848C8A',
    grey700: '#6B7270',
  },
  fontsizes: {
    heading1: '81px',
    heading2: '54px',
    heading3: '36px',
    heading4: '24px',
    heading5: '18px',
    body1: '16px',
    body2: '14px',
  },
  breakpoints: {
    xsmall: '0px',
    small: '576px',
    medium: '768px',
    large: '992px',
    xlarge: '1200px',
    xxlarge: '1400px',
  },

};
