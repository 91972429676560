import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalContext from './context';
import GlobalStyles from './styles/global';
import { theme } from './styles/theme';
import Routing from './routes';

function App() {
  return (
    <GlobalContext>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <GlobalStyles />
          <Routing />
        </BrowserRouter>
      </ThemeProvider>
    </GlobalContext>

  );
}

export default App;
