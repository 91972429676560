import styled from 'styled-components';

export const HeadlineInput = styled.div`

  max-width: 89.7rem;
  display: block;
  align-items: center;
  padding: 2.8rem 8.1rem 13rem 8.1rem;

  .glow {
    box-shadow: inset 0 0 1em ${({ theme, page }) => {
    if (page === 'candidate') return theme.colors.secondary2;
    if (page === 'enterprise') return theme.colors.accent2;

    return theme.grey700;
  }}, 0 0 1rem ${({ theme, page }) => {
  if (page === 'candidate') return theme.colors.secondary2;
  if (page === 'enterprise') return theme.colors.accent2;

  return theme.grey700;
}};
  }

  div, .glow{
    width: 100%;
    max-height: 5rem;
    margin: 0;
    transition: box-shadow 1s cubic-bezier(.9,.03,.69,.22);;
   
    display: flex;
    align-items: center;
    
    button {
      outline: none;
      margin: 0;
      max-height: 5rem;
      min-width: 31.2%;
      font-size: ${({ theme }) => theme.fontsizes.body1};
      background-color: ${({ theme, page }) => {
    if (page === 'candidate') return theme.colors.secondary2;
    if (page === 'enterprise') return theme.colors.accent2;

    return theme.grey700;
  }};
    }
    input {
      min-width: 68.8%;
    }
    div {
      display: block;
    }
  }

  h1 {
    
    font-size: ${({ theme }) => theme.fontsizes.heading1};
    line-height: 1;

    .highlighted {
    color: ${({ theme, page }) => {
    if (page === 'candidate') return theme.colors.secondary2;
    if (page === 'enterprise') return theme.colors.accent2;

    return theme.primary1;
  }};
    }
  }

  h5 {
    font-size: ${({ theme }) => theme.fontsizes.heading5};
    margin-top: 1rem;
    margin-bottom: 3.1rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}){
    div, .glow{
      div{
        margin-bottom: 2rem;
      }
      display: block;
    }
    h5 {
      font-size: ${({ theme }) => theme.fontsizes.body1};
    }
    h1 {
      font-size: ${({ theme }) => theme.fontsizes.heading3};
    }
    button {
      width: 100%;
    }

    padding: 0 3.7rem 11rem 3.7rem;

  }

`;
