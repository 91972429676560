import React from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import {
  DeviceContainer,
  LeftContainer,
  RightContainer,
  Select,
  TextInput,
  Button,
} from '../../components';

const page = {
  title: 'Experiência educacional',
  inputs: {
    courseLabel: 'Curso',
    institutionLabel: 'Instituição',
    levelLabel: 'Nível',
    graduationLabel: 'Ano de Conclusão (ou esperado)',
    coursePlaceholder: 'Ex: Engenheira de Software',
    experienceLabel: 'Tempo de experiência',
    buttonLabel: 'PRÓXIMO',
  },
};
const formsWarnings = {
  course: {
    min: 'Must be at least 2 characters',
    max: 'Must be 15 characters or less',
    required: 'Required',
  },
  institution: {
    min: 'Must be at least 2 characters',
    max: 'Must be 15 characters or less',
    required: 'Required',
  },
  level: {
    required: 'Required',
  },

  graduationYear: {
    required: 'Required',
  },
};
const validationSchema = object({
  course: string()
    .min(2, formsWarnings.course.min)
    .max(15, formsWarnings.course.max)
    .required(formsWarnings.course.required),
  level: string()
    .required(formsWarnings.level.required),
  institution: string()
    .min(2, formsWarnings.course.min)
    .max(25, formsWarnings.course.max)
    .required(formsWarnings.course.required),
  graduationYear: string()
    .required(formsWarnings.level.required),
  // experience: string()
  //   .required(formsWarnings.experience.required),
});

const onSubmit = (values, { setSubmitting, resetForm }) => {
  console.log(values);
  setTimeout(() => {
    alert(JSON.stringify(values));
    resetForm();
    setSubmitting(false);
  }, 100);
};

const initialValues = {
  course: '',
  level: '',
  institution: '',
  graduationYear: '',
};

const levelOptions = [
  'Graduação',
  'Pós-Graduação',
  'Mestrado',
];

const yearOptions = [
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
];

export function EducationalExperience() {
  return (
    <DeviceContainer>
      <LeftContainer type="pink">
        <h1>
          {page.title}
        </h1>
      </LeftContainer>
      <RightContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values, handleChange, handleBlur, handleSubmit, isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>

              <TextInput
                label={page.inputs.courseLabel}
                name="course"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.course}
              />
              <TextInput
                label={page.inputs.institutionLabel}
                name="institution"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.institution}
              />

              <Select
                options={levelOptions}
                label={page.inputs.levelLabel}
                name="level"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.level}
              />

              <Select
                options={yearOptions}
                label={page.inputs.graduationLabel}
                name="graduationYear"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.graduationYear}
              />

              <Button
                type="submit"
                label={isSubmitting ? 'Loading ...' : page.inputs.buttonLabel}
                disabled={isSubmitting}
                state={isSubmitting ? 'disabled' : 'active'}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </DeviceContainer>
  );
}
