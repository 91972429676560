import styled from 'styled-components';

export const ButtonA = styled.button`

  width: 100%;
  height: 6.8rem;
  line-height: 6.8rem; 
  
  margin-bottom: 3.6rem;

  border: none;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 24px 0 33.13px;
  
  cursor: pointer;

  font-size: ${({ theme }) => theme.fontsizes.body1};

  color: ${({ theme }) => theme.colors.grey100};
  
  background: ${({ theme, state }) => {
    if (state === 'active') return theme.colors.vtex1;

    return theme.grey300;
  }};

  transition: 0.2s;

  &:hover {
    box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.1);
  };


`;
