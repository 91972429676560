import styled from 'styled-components';

export const Container = styled.ul`
  margin-bottom: 6.6rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}){
    margin-bottom: 3.2rem;
  }
`;

export const Ball = styled.li`

  width: 4em;
  height: 4em;

  background-color: ${({ theme }) => theme.colors.vtex2};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}){
    width: 3.6em;
    height: 3.6em;
  }

  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin: 0 1em;

  ${({ theme, active }) => active
  && (
    `~li{
      background: ${theme.colors.grey100}
    }
    ~ li::after{
      background: ${theme.colors.grey500}

    }  
  `
  )}

  ::after{

    top: 1.8em;
    right: -2em;
    width: 2em;
    height: .2em;
    content: ' ';

    position: absolute;

    background-color: ${({ theme }) => theme.colors.vtex2};
    z-index: -1;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}){
      top: 1.62em;
      right: -1.8em;
      width: 1.8em;
    }
  }

  :last-child::after {
    display: none;
  }
`;
