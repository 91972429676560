import React from 'react';
import { useField } from 'formik';
import { Container } from './styles';

function StyledSelect({
  label, options, ...props
}) {
  const [field, meta] = useField(props);
  return (
    <Container error={meta.error} touched={meta.touched}>
      <label htmlFor={props.name}> {label}</label>
      <select {...field} {...props}>
        <option disabled value=""> --Selecione uma opção-- </option>
        {options.map((option) => (
          option.area
            ? (
              <optgroup label={option.area} key={option.area}>
                {option.Expertises.map((value) => (
                  <option
                    value={value.id}
                    key={value.name}
                  >{value.name}
                  </option>
                ))}
              </optgroup>
            )
            : <option value={option} key={option}>{option}</option>

        ))}
      </select>
      {meta.touched && meta.error ? (<p>{meta.error}</p>) : ''}
    </Container>
  );
}

export default StyledSelect;
