import React, { useEffect } from 'react';
import { Ball, Container } from './styles';

function Phases({ phasesN, phaseStatus }) {
  const elements = [];

  for (let i = 1; i <= phasesN; i += 1) {
    elements.push(
      <Ball active={phaseStatus === i} key={i} />,
    );
  }

  return (
    <Container>
      {elements}
    </Container>
  );
}

export default Phases;
