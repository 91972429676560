import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from './styles';
import Logo from '../../assets/vectors/logo.svg';
import Button from '../Button';

function StyledHeader({ content, ...props }) {
  const navigate = useNavigate();

  return (
    <Header>
      <img className="logo" src={Logo} alt={content.logoAlt} />
      <button className="h5b" onClick={() => navigate('/')} type="button">
        <h5>{content.candidateLabel}</h5>
      </button>
      <button className="h5b" onClick={() => navigate('/enterprise')} type="button">
        <h5>{content.companyLabel}</h5>
      </button>

      <Button className="subButton" onClick={props.onClickButton} id="buttonHeader" state="active" size="small" label={content.buttonLabel} />
    </Header>
  );
}

export default StyledHeader;
