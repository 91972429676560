import React from 'react';
import { useField } from 'formik';
import {
  Container, ItemDiv,
} from './styles';

function StyledCheckbox({
  label, text, ...props
}) {
  const [field, meta] = useField(props);
  return (
    <Container error={meta.error} touched={meta.touched}>
      <h3>{label}</h3>
      <ItemDiv>
        <input type="checkbox" {...field} {...props} value={label} />
        <label htmlFor={props.name}>{text}</label>
      </ItemDiv>
    </Container>
  );
}

export default StyledCheckbox;
