import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  CompaniesLogos, FAQ, Footer, Header, HeadlineInput, Specialists,
} from '../../components';

import api from '../../services';
import CandidateContext from '../../context/candidatecontext';

const formsWarnings = {
  email: {
    required: 'Required',
    match: 'E-mail não corporativo',
  },
};

const headerContent = {
  candidateLabel: 'para você',
  companyLabel: 'para empresas',
  buttonLabel: 'CADASTRE-SE',
  logoAlt: 'Logo do Commerce talents',
};

const headlineContent = {
  headline1: '',
  highlighted: 'Contrate ',
  headline2: 'rápido os melhores talentos',
  subheadline: 'Cadastre-se e comece a contratar',
  emailPlaceholder: 'Seu melhor e-mail',
  buttonLabel: 'CADASTRE-SE',
};

const specialistsContent = {
  title: 'Somos Especialistas',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
  buttonLabel: 'CADASTRE-SE',
  imgAlt: 'Creativity illustration by Storyset',
};

const companiesContent = {
  companiesLabel: 'Empresas que contratam',
};

const faqContent = {
  label: 'Ainda tem dúvidas?',
  questions: ['1. Eu tenho que pagar algo?',
    '2. Dúvida 2',
    '3. Dúvida 3',
    '4. Dúvida 4'],
};

const footerContent = {
  candidateLabel: 'para você',
  companyLabel: 'para empresas',
  copyright: 'Business illustrations by Storyset',
};

const validationSchema = Yup.object().shape({
  email: Yup.string().matches(
    /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9](?=.{2,61})/,
    formsWarnings.email.match,
  ),
});

const initialValues = {
  email: '',
};

export function Enterprise() {
  const navigate = useNavigate();

  const { state, setState } = useContext(CandidateContext);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const {
      email,
    } = values;
    console.log(state);
    api.post('/enterprise-leads', {
      id: state.enterprise_id,
      email,
    }).then((res) => {
      setState({
        enterprise_id: res.data.id,
        email: res.data.email,
      });
      console.log(state);
      resetForm();
      setSubmitting(false);
      navigate('/registratio/enterprise');
    }).catch((err) => console.log(err));
  };

  function addGlow() {
    const buttonInput = document.getElementById('buttonInput');
    buttonInput.classList.add('glow');
    setTimeout(() => {
      buttonInput.classList.remove('glow');
    }, 1000);
  }

  return (
    <>
      <Header onClickButton={addGlow} content={headerContent} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values, handleChange, handleBlur, handleSubmit, isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <HeadlineInput
              name="email"
              page="enterprise"
              content={headlineContent}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              disabled={isSubmitting}
              state={isSubmitting ? 'disabled' : 'active'}
            />
          </Form>
        )}
      </Formik>
      <CompaniesLogos page="enterprise" content={companiesContent} />
      <Specialists onClickButton={addGlow} hrefButton="#join" content={specialistsContent} />
      <FAQ content={faqContent} />
      <Footer content={footerContent} />
    </>
  );
}
