import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Form, Formik, FieldArray, Field,
} from 'formik';
import * as Yup from 'yup';
import CandidateContext from '../../context/candidatecontext';
import api from '../../services';
import {
  DeviceContainer,
  LeftContainer,
  RightContainer,
  Search,
  Phases,
  SelectExperience,
  Button,
} from '../../components';

const page = {
  title: 'Habilidades',
  description: 'Quais habilidades você tem?',
  inputs: {
    searchPlaceholder: 'Pesquise uma habilidade',
    skillsLabel: 'Selecione suas habilidades',
    buttonLabel: 'Próximo',
  },
};

const initialValues = {
  skills_experience: [],
};

export function Skills() {
  const { state, setState } = useContext(CandidateContext);
  const [skills, setSkills] = useState([]);
  const [experienceTime, setExperienceTime] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const getData = async () => {
      const select = await api.get('/skills');
      setSkills(select.data);
      console.log(select.data);
      const experience = await api.get('/experience-time');
      setExperienceTime(experience.data);
    };
    getData();
  }, []);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log(state.candidate_id);
    const {
      skills_experience,
    } = values;

    console.log(values);
    api.post('/candidates/add_skill', {
      candidate_id: state.candidate_id,
      skills_experience,
    }).then((res) => {
      resetForm();
      setSubmitting(false);
      navigate('/experience');
    }).catch((err) => console.log(err));
  };
  const [skillsExperience, setSkillsExperience] = useState([]);
  return (
    <DeviceContainer>
      <LeftContainer>
        <h1>
          {page.title}
        </h1>
        <h2>
          {page.description}
        </h2>
      </LeftContainer>
      <RightContainer>
        <Phases phasesN={6} phaseStatus={3} />

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({
            values, handleChange, handleBlur, handleSubmit, isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Search
                data={skills}
                placeholder={page.inputs.searchPlaceholder}
                setData={setSkillsExperience}
              />
              <FieldArray name="skills_experience">
                <>
                  {skillsExperience.map((skill, i) => {
                    console.log(skill);
                    return (
                      <SelectExperience
                        label={skill.name}
                        options={experienceTime}
                        name={`skills_experience.${i}.${skill.id}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    );
                  })}
                </>
              </FieldArray>

              <Button
                type="submit"
                label={isSubmitting ? 'Loading ...' : page.inputs.buttonLabel}
                disabled={isSubmitting}
                state={isSubmitting ? 'disabled' : 'active'}
              />

            </Form>
          )}
        </Formik>
      </RightContainer>
    </DeviceContainer>
  );
}
