import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Form, Formik, FieldArray,
} from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import {
  DeviceContainer,
  LeftContainer,
  RightContainer,
  Search,
  Phases,
  Button,
  StyledCheckbox,
  SearchContainer,
  PhoneInput,
} from '../../components';

const page = {
  title: 'Carreira',
  description: 'Como foi a sua história até aqui?',
  inputs: {
    searchPlaceholderCities: 'Pesquise uma cidade',
    searchPlaceholderCompanies: 'Busque empresas',
    citiesLabel: 'Cidades para Trabalhar',
    companiesLabel: 'Privacidade',
    companiesDescription: 'Selecione as empresas que voce não quer que vejam seu perfil',
    buttonLabel: 'Próximo',
    officeLabel: 'Qual modalidade de trabalho você prefere?',
    remoteButtonLabel: 'Remoto',
    officeButtonLabel: 'Presencial',
    phoneLabel: 'Telefone',
    phoneCheck: 'Aceito Ser contactado por telefone',
  },
};

const options = [
  'Básico',
  'Intermediario',
  'Avançado',
  'Fluente',
];

const formsWarnings = {
  companies: {
    required: 'Required',
  },
};

const validationSchema = Yup.object().shape({
  companies: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
    }),
  ),
  cities: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
    }),
  ),
  office: Yup.array().required(),
});

const companies = [
  'VTEX',
  'Google',
  'Microsoft',
];
const cities = [
  'São Paulo',
  'Recife',
  'Natal',
  'Rio de Janeiro',
];

export function Career() {
  const [companiesBlocked, setcompaniesBlocked] = useState([]);
  const [citiesInterest, setcitiesInterest] = useState([]);
  const [office, setOffice] = useState(false);
  const [remote, setRemote] = useState(false);
  const [phone, setPhone] = useState('');

  const initialValues = {
    office: [remote, office],
    phone,
    phoneCheck: '',
    companiesBlocked: [],
    citiesInterest: [],
  };

  const navigate = useNavigate();
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    console.log('I am here');
    alert(JSON.stringify(values));
    resetForm();
    setSubmitting(false);
    navigate('/profile');
  };
  return (
    <DeviceContainer>
      <LeftContainer>
        <h1>
          {page.title}
        </h1>
        <h2>
          {page.description}
        </h2>
      </LeftContainer>
      <RightContainer>
        <Phases phasesN={6} phaseStatus={4} />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values, handleChange, handleBlur, handleSubmit, isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <h1>
                {page.inputs.officeLabel}
              </h1>
              <div className="OfficeButtons">
                <Button
                  size="small"
                  className="SelectButton"
                  label={page.inputs.remoteButtonLabel}
                  onClick={() => (remote ? setRemote(false) : setRemote(true))}
                  type="button"
                  state={remote ? 'active' : 'default'}
                />
                <Button
                  size="small"
                  label={page.inputs.officeButtonLabel}
                  onClick={() => (office ? setOffice(false) : setOffice(true))}
                  type="button"
                  state={office ? 'active' : 'default'}
                />
              </div>
              <Search
                data={cities}
                placeholder={page.inputs.searchPlaceholderCities}
                setData={setcitiesInterest}
              />
              <FieldArray name="citiesInterest">
                <>
                  {citiesInterest.map((city, i) => (
                    <SearchContainer
                      label={city}
                      name={`citiesInterest.${i}.${city}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  ))}
                </>
              </FieldArray>

              <PhoneInput
                label={page.inputs.phoneLabel}
                name="phone"
                type="text"
                onChange={setPhone}
                onBlur={handleBlur}
                international="false"
              />

              <StyledCheckbox
                text={page.inputs.phoneCheck}
                name="phoneCheck"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneCheck}
              />

              <h1>
                {page.inputs.companiesLabel}
              </h1>
              <h2>
                {page.inputs.companiesDescription}
              </h2>
              <Search
                data={companies}
                placeholder={page.inputs.searchPlaceholderCompanies}
                setData={setcompaniesBlocked}
              />
              <FieldArray name="companiesBlocked">
                <>
                  {companiesBlocked.map((company, i) => (
                    <SearchContainer
                      label={company}
                      options={options}
                      name={`companiesBlocked.${i}.${company}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  ))}
                </>
              </FieldArray>

              <Button
                type="submit"
                label={isSubmitting ? 'Loading ...' : page.inputs.buttonLabel}
                disabled={isSubmitting}
                state={isSubmitting ? 'disabled' : 'active'}
              />

            </Form>
          )}
        </Formik>
      </RightContainer>
    </DeviceContainer>
  );
}
