import React from 'react';
import { Form, Formik } from 'formik';
import { object, string, number } from 'yup';
import { useNavigate } from 'react-router-dom';
import {
  DeviceContainer,
  LeftContainer,
  RightContainer,
  TextArea,
  TextInput,
  Button,
  DateContainer,
  StyledCheckbox,
} from '../../components';

const page = {
  title: 'Experiência Profissional',
  inputs: {
    positionLabel: 'Cargo',
    companyLabel: 'Empresa',
    startMonthLabel: 'Início',
    endMonthLabel: 'Fim',
    descriptionLabel: 'Descrição',
    buttonLabel: 'PRÓXIMO',
    positionCheck: 'Emprego atual',
  },
};
const formsWarnings = {
  position: {
    min: 'Must be at least 2 characters',
    max: 'Must be 15 characters or less',
    required: 'Required',
  },
  company: {
    min: 'Must be at least 2 characters',
    max: 'Must be 15 characters or less',
    required: 'Required',
  },
  description: {
    required: 'Required',
  },
};
const validationSchema = object({
  position: string()
    .min(2, formsWarnings.position.min)
    .max(15, formsWarnings.position.max)
    .required(formsWarnings.position.required),
  company: string()
    .min(2, formsWarnings.company.min)
    .max(15, formsWarnings.company.max)
    .required(formsWarnings.company.required),
  // validade automatically
  startYear: number().min(1900).max(2022),
  startMonth: number().min(1).max(12),
  endYear: number().min(1900).max(2022),
  endMonth: number().min(1).max(12),
  description: string()
    .required(formsWarnings.description.required),
});
const onSubmit = (values, { setSubmitting, resetForm }) => {
  setTimeout(() => {
    alert(JSON.stringify(values));
    resetForm();
    setSubmitting(false);
  }, 1500);
};
const initialValues = {
  position: '',
  company: '',
  startYear: '',
  startMonth: '',
  endYear: '',
  endMonth: '',
  description: '',
  positionCheck: false,
};

export function ProfessionalExperience() {
  // const navigate = useNavigate();
  return (
    <DeviceContainer>
      <LeftContainer type="pink">
        <h1>
          {page.title}
        </h1>
        <h2>
          {page.description}
        </h2>
      </LeftContainer>
      <RightContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <TextInput
                label={page.inputs.positionLabel}
                name="position"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.position}
                state={touched.position && errors.position ? 'error' : 'default'}
              />
              <TextInput
                label={page.inputs.companyLabel}
                name="company"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.company}
                state={touched.company && errors.company ? 'error' : 'default'}
              />
              <StyledCheckbox
                text={page.inputs.positionCheck}
                name="positionCheck"
                value={values.positionCheck}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <DateContainer>
                <TextInput
                  className="dateInput"
                  label={page.inputs.startMonthLabel}
                  name="startMonth"
                  type="number"
                  min="1"
                  max="12"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.startMonth}
                  state={touched.startMonth && errors.startMonth ? 'error' : 'default'}
                />
                <div className="bar" />
                <TextInput
                  className="dateInput"
                  name="startYear"
                  type="number"
                  min="1900"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.startYear}
                  state={touched.startYear && errors.startYear ? 'error' : 'default'}
                />
                <div className="noBar" />
                <TextInput
                  className="dateInput"
                  label={page.inputs.endMonthLabel}
                  name="endMonth"
                  type="number"
                  min="1"
                  max="12"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.endMonth}
                  state={touched.endMonth && errors.endMonth ? 'error' : 'default'}
                />
                <div className="bar" />
                <TextInput
                  className="dateInput"
                  name="endYear"
                  type="number"
                  min="1900"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.endYear}
                  state={touched.endYear && errors.endYear ? 'error' : 'default'}
                />
              </DateContainer>
              <TextArea
                label={page.inputs.descriptionLabel}
                name="description"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                state={touched.description && errors.description ? 'error' : 'default'}
              />
              <Button
                type="submit"
                label={isSubmitting ? 'Loading ...' : page.inputs.buttonLabel}
                disabled={isSubmitting}
                state={isSubmitting ? 'disabled' : 'active'}
              />
            </Form>
          )}
        </Formik>
      </RightContainer>
    </DeviceContainer>
  );
}
